import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { SelectItem } from '@sb/ui';

import { PermissionState } from '../../auth/auth.model';
import {
  getPermissionState,
  hasAllPermissions,
  hasPermission,
  PermissionCheck,
  permissionDepartments,
} from '../../auth/permission.helper';
import { convertArrayToObject } from '../../shared/entity.helper';
import { getActiveContracts } from '../contract/contract.service';
import { EnhancedEmployeeModel } from '../employee/employee.model';
import { getEmployee } from '../employee/employee.service';
import { EnhancedDepartmentModel, LocationModel } from '../location/location.model';
import { getDepartmentOptionsPerLocation, getLocationEntities, getLocationTree } from '../location/location.service';
import { EnhancedTeamModel, TeamModel } from '../team/team.model';
import { getTeams } from '../team/team.service';
import { DepartmentModel } from './department.model';
import { getActiveDepartments, getAuthenticatedDepartments, getDepartments } from './department.service';

export const getDepartmentOptionsForAbsencePolicy = createSelector(getActiveDepartments, (departments): SelectItem[] =>
  departments.map(
    (department: DepartmentModel): SelectItem => ({
      text: department.name,
      value: department.id,
    }),
  ),
);

export const getEnhancedDepartmentsEntities = createSelector(
  getDepartments,
  getLocationEntities,
  (departments: DepartmentModel[], locationEntities: Dictionary<LocationModel>) =>
    convertArrayToObject(
      'id',
      departments.map((department) => ({
        ...department,
        location: locationEntities[department.location_id],
      })),
    ),
);

// Needs to be defined here (instead of doing it in team.service.ts)
// To avoid circular imports errors
export const getEnhancedTeams = createSelector(
  getTeams,
  getEnhancedDepartmentsEntities,
  (teams: TeamModel[], departmentEntities: Dictionary<EnhancedDepartmentModel>): Dictionary<EnhancedTeamModel> =>
    convertArrayToObject(
      'id',
      teams.map(
        (team) =>
          ({
            ...team,
            department: departmentEntities[team.department_id],
          }) as EnhancedTeamModel,
      ) as EnhancedTeamModel[],
    ),
);

// Needs to be defined here (instead of doing it in employee.service.ts)
// To avoid circular imports errors
export const getEnhancedEmployee = (id: string) =>
  createSelector(
    getEmployee(id),
    getEnhancedTeams,
    getActiveContracts,
    (employee, enhancedTeams, activeContracts): EnhancedEmployeeModel => ({
      ...employee,
      team: employee.Team.length ? enhancedTeams[employee.Team[0]] : null,
      contract: activeContracts.filter((contract) => contract.user_id === employee.id)?.[0] || null,
    }),
  );

export const getDepartmentTreeOptions = (check: PermissionCheck, ignoreFilter = false) =>
  createSelector(
    getPermissionState,
    getActiveDepartments,
    getLocationEntities,
    (permissionState, departments, locationsById) => {
      const departmentIds = permissionDepartments(check, permissionState);

      const filteredDepartments = !ignoreFilter
        ? departments.filter((department) => !department.deleted && departmentIds.includes(department.id))
        : departments;

      return getLocationTree(filteredDepartments, locationsById);
    },
  );

export const getAuthenticatedDepartmentTreeOptions = createSelector(
  getAuthenticatedDepartments,
  getLocationEntities,
  (departments, locationsById): SelectItem[] =>
    getLocationTree(departments, locationsById).map(
      (location) =>
        ({
          text: location.text,
          value: location.value,
          children: location.children.map((department) => ({
            text: department.text,
            value: department.value,
          })),
        }) as SelectItem,
    ),
);

export const getDepartmentOptions = (check: PermissionCheck, ignoreFilter = false) =>
  createSelector(
    getPermissionState,
    getActiveDepartments,
    getLocationEntities,
    (permissionState, departments, locationsById) => {
      const departmentIds = permissionDepartments(check, permissionState);

      const filteredDepartments = !ignoreFilter
        ? departments.filter((department) => !department.deleted && departmentIds.includes(department.id))
        : departments;

      return getDepartmentOptionsPerLocation(filteredDepartments, locationsById);
    },
  );

export const getEnhancedDepartment = (id: string) =>
  createSelector(
    getEnhancedDepartmentsEntities,
    (enhancedDepartments): EnhancedDepartmentModel => enhancedDepartments[id],
  );

export const getSchedulePermissionsForDepartment = (id: string) =>
  createSelector(getPermissionState, (permissionState: PermissionState) => {
    const canCreateEvent = hasPermission(
      {
        permissions: 'Create event',
        departments: id,
      },
      permissionState,
    );

    const canCreateSchedule = hasPermission(
      {
        permissions: ['Create roster', 'Create own roster'],
        userId: 'me',
        departments: id,
      },
      permissionState,
    );

    const canCreateOpenShift = hasPermission(
      {
        permissions: ['Create roster'],
        departments: id,
      },
      permissionState,
    );

    const canCreateRequiredShift = hasPermission(
      {
        permissions: ['Create required shift'],
        departments: id,
      },
      permissionState,
    );

    return {
      canCreateEvent,
      canCreateSchedule,
      canCreateOpenShift,
      canCreateRequiredShift,
    };
  });

export const hasPermissionToCopySchedule = (id = 'any') =>
  createSelector(getPermissionState, (permissionState: PermissionState) =>
    hasAllPermissions(
      {
        permissions: [
          'View all rosters',
          'Create roster',
          'Create required shift',
          'Delete roster',
          'Delete required shift',
        ],
        userId: 'me',
        departments: id,
      },
      permissionState,
    ),
  );
